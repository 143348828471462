.pageLayout {
  /* padding: 0 10px; */
  margin: auto;
  /* text-align: center; */
  background-color: white;
  height: 100%;
  margin: 0;
  width: 100vw;
  overflow: hidden;
}
.top_profile {
  display: flex;
  align-items: center;
}
.image {
  height: 150px;
  width: 150px;
  margin: auto;
}
.noMobile {
  font-size: 20px;
  color: grey;
  padding: 20px;
}
.headerMainContainer_Mobile {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: ridge;
}
.back_button {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}
.profile_pic_img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.maindivmsgs {
  margin: 55px 0;
  width: 100vw;
}
.textInputContainer {
  height: 50px;
  align-items: center;
  margin: auto;
  display: flex;
  width: 100%;
}

.send_button {
  height: 40px;
  width: 40px;
  background: linear-gradient(45deg, #0091ff, #0000003b);
  margin: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .textInputContainer {
    height: 50px;
    align-items: center;
    /* margin: auto; */
    display: flex;
    background: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
    /* width: 75%; */
  }
}
.upload_button {
  margin: 0 20px;
  color: grey;
  position: absolute;
  right: 12%;
  top: 16px;
}

@media (max-width: 767px) {
  .messaeInputArea {
    height: 25px;
    border-radius: 3px;
    width: 85%;
    margin: 5px;
    outline: none;
    border: 1px solid #a9aeb7;
    font-size: 16px;
    border-radius: 35px;
    padding: 5px;
    /* padding-left: 40px; */
    padding-right: 40px;

  }
}
.userProfilePicture {
  height: 35px;
  width: 35px;
  background-color: rgb(190, 190, 190);
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 10px;
}
.noUserName {
  font-size: 20px;
  color: aliceblue;
  font-weight: bold;
  text-transform: uppercase;
}
.smileyIcon {
  font-size: 23px;
  color: gray;
  position: absolute;
  left: 13px;
  cursor: pointer;
}
.smileyIconClass > aside {
  position: absolute;
  top: -320px;
  width: 100%;
}
