@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&family=Quicksand:wght@300;400;500;600;700&display=swap);



body {
  margin: 0;
   font-family: 'Helvetica Neue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
}

.border{
  border-bottom: 1px solid rgb(141, 141, 141);
  width: 50%;
  margin: auto;
  padding: 15px;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bm-burger-button button{
  width: 50px !important;
  height: 50px !important;
}
.bm-burger-bars{
  height: 1% !important;
}

/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Quicksand:wght@300;400;500;600&display=swap'); */
.mainPage {
  background-color: white;
}

div {
  font-family: "Quicksand", sans-serif;
}

h1 {
  color: #0c2551;

  font-size: x-large;
  font-weight: 700;
}
h2 {
  font-size: large;
}
.p {
  font-weight: 800;
}

.MessageMain_messageList__3oi8_ {
  background-color: rgb(255, 255, 255);
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
  display: flex;
  flex-direction: column;
  bottom: 0;
}
@media (max-width: 768px) {
  .MessageMain_messageList__3oi8_ {
    height: unset;
    width: 100vw;
    overflow: hidden;
    padding: 0px;
  }
}
.MessageMain_docInnerdiv__2-GMc {
  word-break: break-all;
  display: flex;
  align-items: center;
}
.MessageMain_myMessage__3eqcl {
  margin: 10px;
  float: right;
  background-color: blue;
  max-width: 60%;
  padding: 10px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.MessageMain_mydisplayTime__1bMvv {
  font-size: 12px;
  color: #000;
  float: right;
  text-align: right;
  font-weight: 500;
  padding-top: 0px;
}

.MessageMain_otherMessage__2BljW,
.MessageMain_otherMessage_right__3XskT {
  margin: 10px;
  background-color: #e6e6e6;
  max-width: 60%;
  font-size: 14px;
  font-weight: 500;
  float: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
  color: black;
  overflow-wrap: break-word;
}
.MessageMain_otherMessage_right__3XskT {
  background-color: #dbf1fd;
  float: right !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: unset !important;
  border-bottom-left-radius: 10px !important;
}
.MessageMain_otherdisplayTime__F6bMy {
  font-size: 12px;
  color: #000;
  float: left;
  text-align: left;
  font-weight: 500;
  padding-top: 0px;
}

.MessageMain_loading__1-5r- {
  margin: auto;
}

.MessageMain_myImage__1onTG,
.MessageMain_otherImage__1azAI {
  margin: 10px;
  height: 100px;
  width: 200px;
  background-color: #6b646448;
  padding: 3px;
  padding-bottom: 20px;
  color: #fff;
}
.MessageMain_myImage__1onTG {
  background-color: #dbf1fd;
}
.MessageMain_position_relative__3TM88 {
  position: relative;
}
.MessageMain_imageLoader__2V3BF {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background: #ffffff70;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MessageMain_myImage__1onTG:hover {
  cursor: pointer;
  background-color: rgb(140, 140, 255);
}
.MessageMain_myImage__1onTG {
  float: right;
  border-radius: 3px 3px 0 3px;
}
.MessageMain_otherImage__1azAI {
  float: left;
  border-radius: 3px 3px 3px 0;
}

.MessageMain_otherImage__1azAI:hover {
  cursor: pointer;
  background-color: rgb(140, 140, 255);
}
.MessageMain_imageDimension__3NRMc {
  object-fit: cover;
  height: 100px;
  width: 200px;
}
@media (max-width: 768px) {
  @media (max-width: 768px) {
    .MessageMain_myImage__1onTG,
    .MessageMain_otherImage__1azAI,
    .MessageMain_imageDimension__3NRMc {
      height: 100px;
      width: 200px;
    }
  }
}

.MessageMain_myDoc__1qGBP,
.MessageMain_otherDoc__1nsAJ {
  text-decoration: none !important;
  margin: 10px;
  background-color: rgb(206, 206, 206);
  max-width: 60%;
  padding: 5px;
  color: black;
  font-size: 14px;
  font-weight: 500;
}
.MessageMain_myDoc__1qGBP {
  background-color: #dbf1fd;
  float: right;
  border-radius: 10px 10px 0px 10px;
}
.MessageMain_myDoc__1qGBP .MessageMain_docInner__1duJT {
  border-radius: 10px 10px 0px 10px;
}
.MessageMain_otherDoc__1nsAJ {
  float: left;
  border-radius: 10px 10px 10px 0;
}
.MessageMain_otherDoc__1nsAJ .MessageMain_docInner__1duJT {
  border-radius: 10px 10px 10px 0;
}

.MessageMain_docInner__1duJT {
  padding: 3px;
  background-color: rgb(242 254 228);
  transition: all 0.2s ease;
}
.MessageMain_docInner__1duJT:hover {
  background-color: rgb(177, 231, 114);
}
.MessageMain_docTimeAndDate__3HlB2 {
  /* display: flex;
  justify-content: space-between;
  padding: 3px;
  font-size: 12px;
  margin-top: 5px; */
}

.MessageMain_myDoc__1qGBP:hover,
.MessageMain_otherDoc__1nsAJ:hover {
  cursor: pointer;
}
.MessageMain_float_right__1v3DZ {
  float: right !important;
  border-radius: 10px 10px 0 10px !important;
}
.MessageMain_video__1Ephz {
  background: #fff;
  object-fit: cover;
}
.MessageMain_date__yo7fh {
  text-align: center !important;
}
.MessageMain_myDoc__1qGBP:hover,
.MessageMain_otherDoc__1nsAJ:hover,
.MessageMain_diffHover__2AyCg:hover {
  background-color: #8372724f;
}
.MessageMain_markDelivered__10D5y {
  background: #d9e9ff;
  padding: 30px;
  text-align: center;
  font-weight: 500;
  /* margin: 10px; */
  /* border: 4px solid #f5f4f4; */
  color: #09c;
  border-radius: 6px;
  /* width: 30%; */
}
.MessageMain_markDelivered__10D5y > svg,
.MessageMain_payNow__2RGN3 > svg {
  width: 50px;
  height: 34px;
}
.MessageMain_payNow__2RGN3 {
  background: #d9e9ff;
  /* width: 31%; */
  border-radius: 10px 10px 10px 0;
  text-align: center;
  color: #09c;
  padding: 10px 40px;
}
.MessageMain_payNow__2RGN3 div {
  /* color: #fff; */
}
.MessageMain_payNowBtn__3zzL9 p {
  background: #549eb7;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.712);
  width: 100px;
  padding: 6px;
  border-radius: 10px;
  /* margin: 0 auto; */
  margin: 20px auto 0;
  cursor: pointer;
}
.MessageMain_defaultTemplateOther__zBCji,
.MessageMain_defaultTemplateMy__3duQ3 {
  background: #c7c7c7;
  width: 40%;
}
.MessageMain_defaultTemplateOther__zBCji {
  border-radius: 10px 10px 10px 0;
}
.MessageMain_defaultTemplateMy__3duQ3 {
  border-radius: 10px 10px 0 10px;
  float: right;
}
.MessageMain_defaultTemplateOther__zBCji > div {
  border-radius: unset !important;
  float: unset !important;
  padding: 10px;
}
.MessageMain_defaultTemplateMy__3duQ3 > div {
  border-radius: unset !important;
  float: unset !important;
  padding: 10px;
  /* text-align: left; */
}
.MessageMain_dateSpan__2jm9h {
  background: #898989;
  padding: 5px 20px;
  color: #fff;
  border-radius: 10%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.MessageBoard_pageLayout__Red8_ {
  /* margin-top: 10px; */
  /* height: 80vh; */
  background-color: #f2f2f2;
}

.MessageBoard_mainContainer__3mYwn {
  /* border-width: 1px;
  border-style: ridge; */

  background-color: white;

  width: 100%;
  margin: auto;
}
.MessageBoard_headerChat__fFvru {
  height: 50px;

  border-bottom-width: 1px;
  border-bottom-style: ridge;
  background-color: rgb(255, 255, 255);
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgb(247 247 247);
}

.MessageBoard_leftArea__2WbVz {
  overflow-y: auto;
  /* border-width: 1px; */
  /* border-style: ridge; */
  height: 92vh;
  width: 25%;
  background-color: white;
  border-right: 2px solid grey;
}
.MessageBoard_leftArea__2WbVz:hover {
  cursor: pointer;
}
@media (max-width: 768px) {
  .MessageBoard_leftArea__2WbVz {
    overflow-y: auto;

    border-width: 1px;
    border-style: ridge;
    height: unset;
    width: 100%;
    background-color: white;
  }
}
.MessageBoard_userNameAndIconContainer__22GyX {
  display: flex;

  /* margin-bottom: 20px; */
  padding: 10px;
  border-bottom: 1px;
  border-bottom-style: ridge;
  align-items: center;
  transition: all 0.2s ease;
}
@media (min-width: 768px) {
  .MessageBoard_userNameAndIconContainer__22GyX:hover,
  .MessageBoard_userNameAndIconContainer_active__162V2 {
    background-color: rgb(219 241 253);
  }
}
.MessageBoard_userNameText__3ZNix {
  font-size: 14px;
  font-weight: bold;
  padding-left: 10px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}
.MessageBoard_datealignment__dCuTi {
  font-size: 12px;
  color: gray;
}

.MessageBoard_messageText__2Wuoj {
  padding-left: 10px;
  font-size: 13px;
  text-align: left;
  color: rgb(58, 58, 58);
  /* word-break: break-word; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: 1.2em !important;
}

.MessageBoard_leftProfilePicture__3zVmQ {
  height: 50px;
  width: 50px;
  border-radius: 25px;
}
.MessageBoard_userProfilePicture__2Epqi {
  height: 50px;
  width: 50px;
  background-color: rgb(190, 190, 190);
  border-radius: 25px;

  justify-content: center;
  align-items: center;
  display: flex;
}
.MessageBoard_leftAndRightContainerPlacement__al5_W {
  display: flex;
}

.MessageBoard_noUserName__260hB {
  font-size: 20px;
  color: aliceblue;
  font-weight: bold;
  text-transform: uppercase;
}

.MessageBoard_lastMessageDateAndUsername__1spNe {
  display: flex;
  justify-content: space-between;
}
.MessageBoard_lastMessageTime__FmbE7 {
  font-size: 12px;
  color: gray;
  text-align: right;
}

.MessageBoard_innerContainer__17rSN {
  width: 75%;
  height: auto;
}

.MessageBoard_messageAndProfile__k1rkq {
  display: flex;
  height: 74vh;
}

.MessageBoard_profileSection__1XtR_ {
  height: 110%;
  border-width: 1px;

  border-style: ridge;
  width: 30%;
}

.MessageBoard_textMessageProfileContainer__2xZiO {
  /* height: 100%; */
}

.MessageBoard_userNameTexforHeader__3123Z {
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
}

.MessageBoard_messaeInputArea__2swHL {
  height: 30px;
  border-radius: 26px;
  width: 92%;
  margin: 5px;
  outline: none;
  border: 1px solid #a9aeb7;
  font-size: 16px;
  border-radius: 30px;
  padding: 5px;
  padding-left: 40px;
  padding-right: 54px;
}

.MessageBoard_textInputContainer__3Lmxv {
  height: 50px;
  align-items: center;
  /* margin: auto; */
  display: flex;
  background: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;
  width: 73%;
  margin-bottom: 10px;
}
.MessageBoard_send_button__1vaOJ {
  height: 40px;
  width: 40px;
  background: linear-gradient(45deg, #0091ff, #0000003b);
  margin: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.MessageBoard_rightUserProfilePicture__1Y-dI {
  height: 80px;
  width: 80px;
  background-color: rgb(190, 190, 190);
  border-radius: 50px;
  margin: auto;
}
.MessageBoard_rightUserNameAndIconContainer__2aIrj {
  padding: 10px;
  border-bottom: 1px;
  border-bottom-style: ridge;
}

.MessageBoard_nameAndMessage__1uHxY {
  width: 80%;
}
.MessageBoard_sellerUserName__3Cdfh {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}
.MessageBoard_sellerState__37MmF {
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  color: gray;
}

.MessageBoard_profileHeads__1XCsa {
  color: black;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
}

.MessageBoard_profilePoints__1UaA3 {
  font-size: 14px;
  color: gray;
  padding: 5px;
}
.MessageBoard_moreServices__1NpjU {
  color: blueviolet;
  font-size: 14px;
  text-align: center;
  margin: 20px;
}
.MessageBoard_messageAndInput__2AZAQ {
  /* position: relative; */
  width: 100%;
}
.MessageBoard_upload_button__lScNF {
  margin: 0 20px;
  color: grey;
  position: absolute;
  right: 5%;
  top: 16px;
}
.MessageBoard_uploaded_file_main_div__1k4Wp {
  position: absolute;
  bottom: 65px;
  background-color: #d7d7d7;
  width: 69%;
  padding: 10px;
  margin-left: 9px;
  border-radius: 10px;
}
@media (max-width: 1400px) {
  .MessageBoard_uploaded_file_main_div__1k4Wp {
    width: 68%;
  }
}
@media (max-width: 1300px) {
  .MessageBoard_uploaded_file_main_div__1k4Wp {
    width: 67%;
  }
}
@media (max-width: 1200px) {
  .MessageBoard_upload_button__lScNF {
    right: 7%;
  }

  .MessageBoard_uploaded_file_main_div__1k4Wp {
    width: 66%;
  }
}
.MessageBoard_uploaded_file__2iD0O {
  /* width: 98%; */
  background: grey;
  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 200px;
  color: #fff;
  /* border: 1px solid grey; */
  border-radius: 7px;
}
@media (max-width: 767px) {
  .MessageBoard_uploaded_file_main_div__1k4Wp {
    position: absolute;
    bottom: 52px;
    background-color: #d7d7d7;
    width: 90%;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
  }
  .MessageBoard_uploaded_file__2iD0O {
    width: 100%;
  }
}
.MessageBoard_uploaded_file__2iD0O img,
.MessageBoard_uploaded_file__2iD0O video {
  max-height: 100px;
  min-height: 100px;
  object-fit: cover;
  /* border: 2px solid grey; */
  max-width: 100%;
  /* border-radius: 5px 5px 0 0; */
}
@media (max-width: 767px) {
  .MessageBoard_uploaded_file__2iD0O img,
  .MessageBoard_uploaded_file__2iD0O video {
    max-height: 150px;
    min-height: 150px;
    object-fit: cover;
    /* border: 2px solid grey; */
    width: 100%;
    /* border-radius: 5px 5px 0 0; */
  }
}
.MessageBoard_uploaded_file_Cross__F-YiT {
  position: absolute;
  right: 0;
  color: #b12e2e;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffab;
}

.MessageBoard_uploaded_file_Cross__F-YiT > svg {
  color: #fff;
}
.MessageBoard_uploaded_file_Cross__F-YiT:hover {
  background: #b12e2e83;
  color: rgb(255, 255, 255) !important;
}
.MessageBoard_cursor_pointer__37HUw {
  cursor: pointer;
}
.MessageBoard_uploaded_filename_div__3qlCt {
  padding: 4px 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: 1.2em !important;
}
.MessageBoard_mainContainerMsgBoard__x8Crx {
  height: 100vh;
  overflow: hidden;
}
.MessageBoard_smileyIcon__3k2yh {
  font-size: 23px;
  color: gray;
  position: absolute;
  left: 13px;
  cursor: pointer;
}
.MessageBoard_smileyIconClass__1piMl > aside {
    position: absolute !important;
    top: -320px;
    width: 100% !important;
}

.RIHeader_headerMainContainer__2PE6K {
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.RIHeader_headerMainContainer_Mobile__3bM8x {
  width: 100%;
  padding: 10px;

  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: ridge;
}
.RIHeader_back_button__3DASF {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}
.RIHeader_riLogo__3ROCC {
  padding: 10px;
  margin-left: 10px;
}
.RIHeader_riLogo_Mobile__NH2Vs {
  margin: auto;
}

.RIHeader_riLogo_MobileBack__1sAAU {
  margin: auto;
}
.RIHeader_heading1__1O-40 {
  font-size: 16px;
  padding: 5px;
  padding-right: 15px;
}
.RIHeader_heading1__1O-40:hover {
  cursor: pointer;
  color: #1778f2;
}
.RIHeader_hamburger__2y39O {
  padding-left: 15px;
}

.RIHeader_backIcon__K9o6U {
  color: #0c2551;
  padding: 5px;
  padding-left: 2%;
}
.RIHeader_join__v6grn {
  padding: 5px;
  padding-left: 2%;
}
.RIHeader_menuOption__DL70S {
  display: flex;
  align-self: center;

  align-items: center;
  justify-content: space-between;
  width: auto;

  margin-right: 30px;
}
.RIHeader_menuTitle__tkuP4 {
  font-weight: bold;

  margin-right: 30px;
  color: rgb(36, 36, 36);
}

.RIHeader_menuTitle__tkuP4:hover {
  color: rgb(209, 209, 209);
  cursor: pointer;
}

.RIHeader_logoAndMenu__2Tawd {
  display: flex;
  padding-left: 2%;
}
.RIHeader_menuWrapper__UTU8u {
  background-color: #ffffff;
  height: 100%;
}

.RIHeader_close_button__C7j0W {
  display: flex;
  justify-content: flex-end;
  color: #ffffff;
}
.RIHeader_JoinBtn_Link__wtE4W {
  text-decoration: none;
  color: #fff;
}
.RIHeader_drawer_menu_header__dEv_g {
  background-color: #1778f2;
  padding: 15px 15px 33px 15px;
}
.RIHeader_menuProfile_image__2UCrc {
  width: 85px;
  height: 85px;
  background-color: #a38585;
  border-radius: 50%;
}
.RIHeader_menu_profile__2PJN1 {
  display: flex;
  align-items: center;
  width: 93%;
  justify-content: space-evenly;
  color: #ffffff;
}
.RIHeader_menuProfile_name__1vDFB {
  margin: 0px;
}
.RIHeader_menuProfile_button__11j8p {
  padding: 4px 14px;
  margin-top: 13px;
  border-radius: 10px;
  border: none;
}
.RIHeader_menuNav_contaner__3in12 {
  background-color: #ffffff;
}
.RIHeader_menuNav__2SDvn {
  padding: 24px 30px;
}
.RIHeader_link_container__bZ_Jm {
  display: flex;
  align-items: center;
  height: 50px;
}
.RIHeader_link__11wsz {
  text-decoration: none;
  color: #000;
  margin-left: 15px;
}
.RIHeader_border__2MePf {
  border-bottom: 1px solid #bdb8b8;
  margin: 30px 0px 30px 15px;
}
.RIHeader_link_bottom__ePJTp {
  color: #0c2551;
  text-decoration: none;
  margin-left: 15px;
}
.RIHeader_menu_icon__2481_ {
  color: #0c2551;
  height: 22px;
  width: 22px;
}

.MessageBoardMobileError_pageLayout__2x0Be {
  padding: 10px;
  margin: auto;
  text-align: center;
  background-color: white;
  height: 100%;
  margin: 10px;
}

.MessageBoardMobileError_image__3lxyZ {
  height: 150px;
  width: 150px;
  margin: auto;
}
.MessageBoardMobileError_noMobile__1GLFf {
  font-size: 20px;
  color: grey;
  padding: 20px;
}
.MessageBoardMobileError_noChatFound__3Ujbc {
  padding: 140px 10px;
  margin: auto;
  text-align: center;
  background-color: white;
  height: 100%;
  margin: 10px;
}
.MessageBoardMobileError_hyperlinks__10RFn a {
  text-decoration: none !important;
}

.MessageBoardMobile_pageLayout__1fOWV {
  /* padding: 0 10px; */
  margin: auto;
  /* text-align: center; */
  background-color: white;
  height: 100%;
  margin: 0;
  width: 100vw;
  overflow: hidden;
}
.MessageBoardMobile_top_profile__7w0BN {
  display: flex;
  align-items: center;
}
.MessageBoardMobile_image__1Eh1t {
  height: 150px;
  width: 150px;
  margin: auto;
}
.MessageBoardMobile_noMobile__31ILk {
  font-size: 20px;
  color: grey;
  padding: 20px;
}
.MessageBoardMobile_headerMainContainer_Mobile__3x0CQ {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: ridge;
}
.MessageBoardMobile_back_button__3ylnZ {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}
.MessageBoardMobile_profile_pic_img__1g4aW {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.MessageBoardMobile_maindivmsgs__2dFHw {
  margin: 55px 0;
  width: 100vw;
}
.MessageBoardMobile_textInputContainer__3kMcc {
  height: 50px;
  align-items: center;
  margin: auto;
  display: flex;
  width: 100%;
}

.MessageBoardMobile_send_button__2Onll {
  height: 40px;
  width: 40px;
  background: linear-gradient(45deg, #0091ff, #0000003b);
  margin: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
@media (max-width: 768px) {
  .MessageBoardMobile_textInputContainer__3kMcc {
    height: 50px;
    align-items: center;
    /* margin: auto; */
    display: flex;
    background: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
    /* width: 75%; */
  }
}
.MessageBoardMobile_upload_button__3K8UT {
  margin: 0 20px;
  color: grey;
  position: absolute;
  right: 12%;
  top: 16px;
}

@media (max-width: 767px) {
  .MessageBoardMobile_messaeInputArea__3ptBd {
    height: 25px;
    border-radius: 3px;
    width: 85%;
    margin: 5px;
    outline: none;
    border: 1px solid #a9aeb7;
    font-size: 16px;
    border-radius: 35px;
    padding: 5px;
    /* padding-left: 40px; */
    padding-right: 40px;

  }
}
.MessageBoardMobile_userProfilePicture__FLt7S {
  height: 35px;
  width: 35px;
  background-color: rgb(190, 190, 190);
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 10px;
}
.MessageBoardMobile_noUserName__3B3ow {
  font-size: 20px;
  color: aliceblue;
  font-weight: bold;
  text-transform: uppercase;
}
.MessageBoardMobile_smileyIcon__YYJkd {
  font-size: 23px;
  color: gray;
  position: absolute;
  left: 13px;
  cursor: pointer;
}
.MessageBoardMobile_smileyIconClass__28xKE > aside {
  position: absolute;
  top: -320px;
  width: 100%;
}

