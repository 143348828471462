/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.pageLayout {
  /* margin-top: 10px; */
  /* height: 80vh; */
  background-color: #f2f2f2;
}

.mainContainer {
  /* border-width: 1px;
  border-style: ridge; */

  background-color: white;

  width: 100%;
  margin: auto;
}
.headerChat {
  height: 50px;

  border-bottom-width: 1px;
  border-bottom-style: ridge;
  background-color: rgb(255, 255, 255);
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgb(247 247 247);
}

.leftArea {
  overflow-y: auto;
  /* border-width: 1px; */
  /* border-style: ridge; */
  height: 92vh;
  width: 25%;
  background-color: white;
  border-right: 2px solid grey;
}
.leftArea:hover {
  cursor: pointer;
}
@media (max-width: 768px) {
  .leftArea {
    overflow-y: auto;

    border-width: 1px;
    border-style: ridge;
    height: unset;
    width: 100%;
    background-color: white;
  }
}
.userNameAndIconContainer {
  display: flex;

  /* margin-bottom: 20px; */
  padding: 10px;
  border-bottom: 1px;
  border-bottom-style: ridge;
  align-items: center;
  transition: all 0.2s ease;
}
@media (min-width: 768px) {
  .userNameAndIconContainer:hover,
  .userNameAndIconContainer_active {
    background-color: rgb(219 241 253);
  }
}
.userNameText {
  font-size: 14px;
  font-weight: bold;
  padding-left: 10px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}
.datealignment {
  font-size: 12px;
  color: gray;
}

.messageText {
  padding-left: 10px;
  font-size: 13px;
  text-align: left;
  color: rgb(58, 58, 58);
  /* word-break: break-word; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: 1.2em !important;
}

.leftProfilePicture {
  height: 50px;
  width: 50px;
  border-radius: 25px;
}
.userProfilePicture {
  height: 50px;
  width: 50px;
  background-color: rgb(190, 190, 190);
  border-radius: 25px;

  justify-content: center;
  align-items: center;
  display: flex;
}
.leftAndRightContainerPlacement {
  display: flex;
}

.noUserName {
  font-size: 20px;
  color: aliceblue;
  font-weight: bold;
  text-transform: uppercase;
}

.lastMessageDateAndUsername {
  display: flex;
  justify-content: space-between;
}
.lastMessageTime {
  font-size: 12px;
  color: gray;
  text-align: right;
}

.innerContainer {
  width: 75%;
  height: auto;
}

.messageAndProfile {
  display: flex;
  height: 74vh;
}

.profileSection {
  height: 110%;
  border-width: 1px;

  border-style: ridge;
  width: 30%;
}

.textMessageProfileContainer {
  /* height: 100%; */
}

.userNameTexforHeader {
  font-size: 16px;
  font-weight: 600;
  padding: 10px;
}

.messaeInputArea {
  height: 30px;
  border-radius: 26px;
  width: 92%;
  margin: 5px;
  outline: none;
  border: 1px solid #a9aeb7;
  font-size: 16px;
  border-radius: 30px;
  padding: 5px;
  padding-left: 40px;
  padding-right: 54px;
}

.textInputContainer {
  height: 50px;
  align-items: center;
  /* margin: auto; */
  display: flex;
  background: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;
  width: 73%;
  margin-bottom: 10px;
}
.send_button {
  height: 40px;
  width: 40px;
  background: linear-gradient(45deg, #0091ff, #0000003b);
  margin: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.rightUserProfilePicture {
  height: 80px;
  width: 80px;
  background-color: rgb(190, 190, 190);
  border-radius: 50px;
  margin: auto;
}
.rightUserNameAndIconContainer {
  padding: 10px;
  border-bottom: 1px;
  border-bottom-style: ridge;
}

.nameAndMessage {
  width: 80%;
}
.sellerUserName {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}
.sellerState {
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  color: gray;
}

.profileHeads {
  color: black;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
}

.profilePoints {
  font-size: 14px;
  color: gray;
  padding: 5px;
}
.moreServices {
  color: blueviolet;
  font-size: 14px;
  text-align: center;
  margin: 20px;
}
.messageAndInput {
  /* position: relative; */
  width: 100%;
}
.upload_button {
  margin: 0 20px;
  color: grey;
  position: absolute;
  right: 5%;
  top: 16px;
}
.uploaded_file_main_div {
  position: absolute;
  bottom: 65px;
  background-color: #d7d7d7;
  width: 69%;
  padding: 10px;
  margin-left: 9px;
  border-radius: 10px;
}
@media (max-width: 1400px) {
  .uploaded_file_main_div {
    width: 68%;
  }
}
@media (max-width: 1300px) {
  .uploaded_file_main_div {
    width: 67%;
  }
}
@media (max-width: 1200px) {
  .upload_button {
    right: 7%;
  }

  .uploaded_file_main_div {
    width: 66%;
  }
}
.uploaded_file {
  /* width: 98%; */
  background: grey;
  text-align: center;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 200px;
  color: #fff;
  /* border: 1px solid grey; */
  border-radius: 7px;
}
@media (max-width: 767px) {
  .uploaded_file_main_div {
    position: absolute;
    bottom: 52px;
    background-color: #d7d7d7;
    width: 90%;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
  }
  .uploaded_file {
    width: 100%;
  }
}
.uploaded_file img,
.uploaded_file video {
  max-height: 100px;
  min-height: 100px;
  object-fit: cover;
  /* border: 2px solid grey; */
  max-width: 100%;
  /* border-radius: 5px 5px 0 0; */
}
@media (max-width: 767px) {
  .uploaded_file img,
  .uploaded_file video {
    max-height: 150px;
    min-height: 150px;
    object-fit: cover;
    /* border: 2px solid grey; */
    width: 100%;
    /* border-radius: 5px 5px 0 0; */
  }
}
.uploaded_file_Cross {
  position: absolute;
  right: 0;
  color: #b12e2e;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffffab;
}

.uploaded_file_Cross > svg {
  color: #fff;
}
.uploaded_file_Cross:hover {
  background: #b12e2e83;
  color: rgb(255, 255, 255) !important;
}
.cursor_pointer {
  cursor: pointer;
}
.uploaded_filename_div {
  padding: 4px 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: 1.2em !important;
}
.mainContainerMsgBoard {
  height: 100vh;
  overflow: hidden;
}
.smileyIcon {
  font-size: 23px;
  color: gray;
  position: absolute;
  left: 13px;
  cursor: pointer;
}
.smileyIconClass > aside {
    position: absolute !important;
    top: -320px;
    width: 100% !important;
}
