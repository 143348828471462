.headerMainContainer {
  width: 100%;
  align-items: center;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.headerMainContainer_Mobile {
  width: 100%;
  padding: 10px;

  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: ridge;
}
.back_button {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}
.riLogo {
  padding: 10px;
  margin-left: 10px;
}
.riLogo_Mobile {
  margin: auto;
}

.riLogo_MobileBack {
  margin: auto;
}
.heading1 {
  font-size: 16px;
  padding: 5px;
  padding-right: 15px;
}
.heading1:hover {
  cursor: pointer;
  color: #1778f2;
}
.hamburger {
  padding-left: 15px;
}

.backIcon {
  color: #0c2551;
  padding: 5px;
  padding-left: 2%;
}
.join {
  padding: 5px;
  padding-left: 2%;
}
.menuOption {
  display: flex;
  align-self: center;

  align-items: center;
  justify-content: space-between;
  width: auto;

  margin-right: 30px;
}
.menuTitle {
  font-weight: bold;

  margin-right: 30px;
  color: rgb(36, 36, 36);
}

.menuTitle:hover {
  color: rgb(209, 209, 209);
  cursor: pointer;
}

.logoAndMenu {
  display: flex;
  padding-left: 2%;
}
.menuWrapper {
  background-color: #ffffff;
  height: 100%;
}

.close_button {
  display: flex;
  justify-content: flex-end;
  color: #ffffff;
}
.JoinBtn_Link {
  text-decoration: none;
  color: #fff;
}
.drawer_menu_header {
  background-color: #1778f2;
  padding: 15px 15px 33px 15px;
}
.menuProfile_image {
  width: 85px;
  height: 85px;
  background-color: #a38585;
  border-radius: 50%;
}
.menu_profile {
  display: flex;
  align-items: center;
  width: 93%;
  justify-content: space-evenly;
  color: #ffffff;
}
.menuProfile_name {
  margin: 0px;
}
.menuProfile_button {
  padding: 4px 14px;
  margin-top: 13px;
  border-radius: 10px;
  border: none;
}
.menuNav_contaner {
  background-color: #ffffff;
}
.menuNav {
  padding: 24px 30px;
}
.link_container {
  display: flex;
  align-items: center;
  height: 50px;
}
.link {
  text-decoration: none;
  color: #000;
  margin-left: 15px;
}
.border {
  border-bottom: 1px solid #bdb8b8;
  margin: 30px 0px 30px 15px;
}
.link_bottom {
  color: #0c2551;
  text-decoration: none;
  margin-left: 15px;
}
.menu_icon {
  color: #0c2551;
  height: 22px;
  width: 22px;
}
