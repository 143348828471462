.messageList {
  background-color: rgb(255, 255, 255);
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
  display: flex;
  flex-direction: column;
  bottom: 0;
}
@media (max-width: 768px) {
  .messageList {
    height: unset;
    width: 100vw;
    overflow: hidden;
    padding: 0px;
  }
}
.docInnerdiv {
  word-break: break-all;
  display: flex;
  align-items: center;
}
.myMessage {
  margin: 10px;
  float: right;
  background-color: blue;
  max-width: 60%;
  padding: 10px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.mydisplayTime {
  font-size: 12px;
  color: #000;
  float: right;
  text-align: right;
  font-weight: 500;
  padding-top: 0px;
}

.otherMessage,
.otherMessage_right {
  margin: 10px;
  background-color: #e6e6e6;
  max-width: 60%;
  font-size: 14px;
  font-weight: 500;
  float: left;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px;
  color: black;
  overflow-wrap: break-word;
}
.otherMessage_right {
  background-color: #dbf1fd;
  float: right !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: unset !important;
  border-bottom-left-radius: 10px !important;
}
.otherdisplayTime {
  font-size: 12px;
  color: #000;
  float: left;
  text-align: left;
  font-weight: 500;
  padding-top: 0px;
}

.loading {
  margin: auto;
}

.myImage,
.otherImage {
  margin: 10px;
  height: 100px;
  width: 200px;
  background-color: #6b646448;
  padding: 3px;
  padding-bottom: 20px;
  color: #fff;
}
.myImage {
  background-color: #dbf1fd;
}
.position_relative {
  position: relative;
}
.imageLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background: #ffffff70;
  display: flex;
  justify-content: center;
  align-items: center;
}
.myImage:hover {
  cursor: pointer;
  background-color: rgb(140, 140, 255);
}
.myImage {
  float: right;
  border-radius: 3px 3px 0 3px;
}
.otherImage {
  float: left;
  border-radius: 3px 3px 3px 0;
}

.otherImage:hover {
  cursor: pointer;
  background-color: rgb(140, 140, 255);
}
.imageDimension {
  object-fit: cover;
  height: 100px;
  width: 200px;
}
@media (max-width: 768px) {
  @media (max-width: 768px) {
    .myImage,
    .otherImage,
    .imageDimension {
      height: 100px;
      width: 200px;
    }
  }
}

.myDoc,
.otherDoc {
  text-decoration: none !important;
  margin: 10px;
  background-color: rgb(206, 206, 206);
  max-width: 60%;
  padding: 5px;
  color: black;
  font-size: 14px;
  font-weight: 500;
}
.myDoc {
  background-color: #dbf1fd;
  float: right;
  border-radius: 10px 10px 0px 10px;
}
.myDoc .docInner {
  border-radius: 10px 10px 0px 10px;
}
.otherDoc {
  float: left;
  border-radius: 10px 10px 10px 0;
}
.otherDoc .docInner {
  border-radius: 10px 10px 10px 0;
}

.docInner {
  padding: 3px;
  background-color: rgb(242 254 228);
  transition: all 0.2s ease;
}
.docInner:hover {
  background-color: rgb(177, 231, 114);
}
.docTimeAndDate {
  /* display: flex;
  justify-content: space-between;
  padding: 3px;
  font-size: 12px;
  margin-top: 5px; */
}

.myDoc:hover,
.otherDoc:hover {
  cursor: pointer;
}
.float_right {
  float: right !important;
  border-radius: 10px 10px 0 10px !important;
}
.video {
  background: #fff;
  object-fit: cover;
}
.date {
  text-align: center !important;
}
.myDoc:hover,
.otherDoc:hover,
.diffHover:hover {
  background-color: #8372724f;
}
.markDelivered {
  background: #d9e9ff;
  padding: 30px;
  text-align: center;
  font-weight: 500;
  /* margin: 10px; */
  /* border: 4px solid #f5f4f4; */
  color: #09c;
  border-radius: 6px;
  /* width: 30%; */
}
.markDelivered > svg,
.payNow > svg {
  width: 50px;
  height: 34px;
}
.payNow {
  background: #d9e9ff;
  /* width: 31%; */
  border-radius: 10px 10px 10px 0;
  text-align: center;
  color: #09c;
  padding: 10px 40px;
}
.payNow div {
  /* color: #fff; */
}
.payNowBtn p {
  background: #549eb7;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.712);
  width: 100px;
  padding: 6px;
  border-radius: 10px;
  /* margin: 0 auto; */
  margin: 20px auto 0;
  cursor: pointer;
}
.defaultTemplateOther,
.defaultTemplateMy {
  background: #c7c7c7;
  width: 40%;
}
.defaultTemplateOther {
  border-radius: 10px 10px 10px 0;
}
.defaultTemplateMy {
  border-radius: 10px 10px 0 10px;
  float: right;
}
.defaultTemplateOther > div {
  border-radius: unset !important;
  float: unset !important;
  padding: 10px;
}
.defaultTemplateMy > div {
  border-radius: unset !important;
  float: unset !important;
  padding: 10px;
  /* text-align: left; */
}
.dateSpan {
  background: #898989;
  padding: 5px 20px;
  color: #fff;
  border-radius: 10%;
}
