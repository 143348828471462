


body {
  margin: 0;
   font-family: 'Helvetica Neue',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
}

.border{
  border-bottom: 1px solid rgb(141, 141, 141);
  width: 50%;
  margin: auto;
  padding: 15px;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bm-burger-button button{
  width: 50px !important;
  height: 50px !important;
}
.bm-burger-bars{
  height: 1% !important;
}
