.pageLayout {
  padding: 10px;
  margin: auto;
  text-align: center;
  background-color: white;
  height: 100%;
  margin: 10px;
}

.image {
  height: 150px;
  width: 150px;
  margin: auto;
}
.noMobile {
  font-size: 20px;
  color: grey;
  padding: 20px;
}
.noChatFound {
  padding: 140px 10px;
  margin: auto;
  text-align: center;
  background-color: white;
  height: 100%;
  margin: 10px;
}
.hyperlinks a {
  text-decoration: none !important;
}
